import React from 'react';
import { Link } from 'gatsby';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import facebook from '../../img/social/facebook-app-symbol.svg';
import instagram from '../../img/social/instagram.svg';
import twitter from '../../img/social/twitter-logo-silhouette.svg';

import * as Styles from './footer.module.scss';

const Footer = () => {
	return (
		<footer className={Styles.footer}>
			<Container maxWidth="lg">
				<section>
					<Grid container direction="row" justifyContent="center" spacing={2}>
						<Grid item xs={12} md={4}>
							<Typography variant="footerH3" component="h3">
								Contact Information
							</Typography>
							<div>
								<p className={Styles.footerText}>
									Love.Live.Dance, LLC
									<br />
									162 Holland Avenue #2
									<br />
									Morgantown, WV 26501
									<br />
									304-449-4030
								</p>
							</div>
						</Grid>
						<Grid item xs={12} md={4}>
							<Typography variant="footerH3" component="h3">
								Follow Us Online
							</Typography>
							<a
								title="facebook"
								href="https://www.facebook.com/lovelivedancestudio"
								className={`${Styles.socialMediaButton} ${Styles.facebookButton}`}>
								<img
									src={facebook}
									alt="Facebook"
									className={Styles.socialMediaImage}
								/>
							</a>
							<a
								title="instagram"
								href="https://instagram.com/lovelivedanceslc"
								className={`${Styles.socialMediaButton} ${Styles.instagramButton}`}>
								<img
									src={instagram}
									alt="Instagram"
									className={Styles.socialMediaImage}
								/>
							</a>
							<a
								title="twitter"
								href="https://twitter.com/lovelivedanceut"
								className={`${Styles.socialMediaButton} ${Styles.twitterButton}`}>
								<img
									src={twitter}
									alt="Twitter"
									className={Styles.socialMediaImage}
								/>
							</a>
						</Grid>
						<Grid item xs={12} md={4}>
							<Typography variant="footerH3" component="h3">
								Navigation
							</Typography>
							<div>
								<ul className={Styles.footerMenu}>
									<li>
										<Link to="/">Home</Link>
									</li>
									<li>
										<Link to="/competitive-dancesport-lessons/">
											{' '}
											Competitive Dancesport{' '}
										</Link>
									</li>
									<li>
										<Link to="/ballroom-dancing/">
											{' '}
											Ballroom Dance Lessons{' '}
										</Link>
									</li>
									<li>
										<Link to="/wedding-dance/">Wedding Dance Lessons</Link>
									</li>
									<li>
										<Link to="/teen-dance/">Teen Dance Class</Link>
									</li>
									<li>
										<Link to="/couples-dance-lessons/">
											Lessons for Couples
										</Link>
									</li>
									<li>
										<Link to="/blog/">Blog</Link>
									</li>
									<li>
										<Link to="/contact/">Contact</Link>
									</li>
									<li>
										<Link to="/gallery/">Gallery</Link>
									</li>
									<li>
										<Link to="/about/">About</Link>
									</li>
									<li>
										<Link to="/privacy-policy/">Privacy Policy</Link>
									</li>
								</ul>
							</div>
						</Grid>
						<Grid container item xs={12} justifyContent="center">
							<span className={Styles.footerText}>
								Copyright 2019-2023 Love.Live.Dance, LLC
							</span>
						</Grid>
					</Grid>
				</section>
			</Container>
		</footer>
	);
};

export default Footer;
